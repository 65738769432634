import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import VueEasytable from "vue-easytable"
import 'ant-design-vue/dist/antd.css'
import "vue-easytable/libs/theme-default/index.css"

Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(VueEasytable)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
