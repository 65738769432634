<template>
  <div>
    登录中...
    <div>{{ code }}</div>
  </div>
</template>

<script>
import * as dd from 'dingtalk-jsapi'
export default {
  name: 'Login',
  data() {
    return {
      code: undefined
    }
  },
  mounted() {
    dd.ready(() => {
      console.log('dd ready')
      // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
      dd.runtime.permission.requestAuthCode({
          corpId: "dinge4bc89cece3b9e2835c2f4657eb6378f",
          onSuccess: (result) => {
            console.log(result)
            this.code = result.code
          /*{
              code: 'hYLK98jkf0m' //string authCode
          }*/
          },
          onFail : (err) => {
            console.log(err)
          }
    
      });
    });
    // this.$router.push({name: 'feeStatic'})
  },
}
</script>

<style>

</style>